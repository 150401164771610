import request from '@/request';
export default class StoragesApi {
    static findAll() {
        return request.get('storages').then(({ data }) => data);
    }
    static create(body) {
        return request.post('storages', body);
    }
    static findById(id) {
        return request.get(`storages/${id}`).then(({ data }) => data);
    }
    static update(id, body) {
        return request.put(`storages/${id}`, body);
    }
    static delete(id) {
        return request.delete(`storages/${id}`);
    }
}
