import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { StorageType } from '@flosum-backup/contracts';
import VueModal from '@/classes/vue-modal';
import StoragesApi from '@/services/api/storages.api';
import CloudStoragesDirector from '@/builders/cloud-storages.director';
let CloudStoragesManageModal = class CloudStoragesManageModal extends VueModal {
    constructor() {
        super(...arguments);
        this.form = null;
        this.loading = false;
        this.storageState = false;
    }
    async created() {
        this.createFormGroup();
        this.updateForm();
        if (this.id) {
            await this.fetchStorage();
        }
        this.formGroup.getControl('type').onChange((type) => {
            this.updateFormGroup(type);
            this.updateForm(type);
        });
    }
    beforeDestroy() {
        this.formGroup?.destroy();
    }
    onModalHidden() {
        this.resolved(this.storageState);
    }
    async onSubmit() {
        try {
            this.formGroup.touch();
            if (!this.formGroup.isValid) {
                return;
            }
            this.loading = true;
            if (this.id) {
                await StoragesApi.update(this.id, this.formGroup.getValue());
                this.$toast.success('Storage successfully updated');
            }
            else {
                await StoragesApi.create(this.formGroup.getValue());
                this.$toast.success('Storage successfully added');
            }
            this.storageState = true;
            this.close();
        }
        catch (error) {
            this.$toast.error(error.message);
            this.loading = false;
        }
    }
    async fetchStorage() {
        try {
            this.loading = true;
            const { name, type, credentials } = await StoragesApi.findById(this.id);
            this.updateFormGroup(type);
            this.updateForm(type);
            this.formGroup.patchValue({
                name,
                type,
                credentials: JSON.parse(credentials),
            });
            this.loading = false;
        }
        catch (error) {
            this.$toast.error(error.message);
            this.close();
        }
    }
    createFormGroup() {
        this.formGroup = CloudStoragesDirector.useCloudStorageFormGroup();
        if (this.id) {
            this.formGroup.getControl('name').disable();
            this.formGroup.getControl('type').disable();
        }
    }
    updateFormGroup(type) {
        const controls = CloudStoragesDirector.useCloudStorageSpecificControls(type);
        this.formGroup.removeControl('credentials');
        if (controls) {
            if (this.id) {
                switch (type) {
                    case StorageType.AWS_S3:
                        controls.getControl('bucketName').disable();
                        break;
                }
            }
            this.formGroup.addControl('credentials', controls);
        }
    }
    updateForm(type) {
        this.form = CloudStoragesDirector.useCloudStorageForm(this.formGroup, type);
    }
};
__decorate([
    Prop({
        default: null,
    })
], CloudStoragesManageModal.prototype, "id", void 0);
CloudStoragesManageModal = __decorate([
    Component({})
], CloudStoragesManageModal);
export default CloudStoragesManageModal;
