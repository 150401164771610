import { StorageType } from '@flosum-backup/contracts';
import FormGroup from '@/classes/form-group';
import FormControl from '@/classes/form-control';
import FormControlsBuilder from '@/builders/form-controls.builder';
import required from '@/validators/required.validator';
export default class CloudStoragesDirector {
    static useCloudStorageFormGroup() {
        const formGroup = new FormGroup();
        formGroup.addControl('name', new FormControl('', [required]));
        formGroup.addControl('type', new FormControl(null, [required]));
        return formGroup;
    }
    static useCloudStorageForm(formGroup, type) {
        const builder = new FormControlsBuilder(formGroup);
        builder.addInput('name', 'Name');
        builder.addSelect('type', 'Storage type', {
            options: [StorageType.AWS_S3],
        });
        if (type) {
            CloudStoragesDirector.addStorageCredentialsControlsByType(type, builder);
        }
        return builder.build();
    }
    static useCloudStorageSpecificControls(type) {
        switch (type) {
            case StorageType.AWS_S3:
                return new FormGroup({
                    bucketName: new FormControl('', [required]),
                    accessKeyId: new FormControl('', [required]),
                    secretAccessKey: new FormControl('', [required]),
                });
        }
        return null;
    }
    static addStorageCredentialsControlsByType(type, builder) {
        switch (type) {
            case StorageType.AWS_S3:
                builder
                    .addInput('credentials.bucketName', 'Bucket name')
                    .addInput('credentials.accessKeyId', 'Access key id')
                    .addInput('credentials.secretAccessKey', 'Secret Access Key');
        }
    }
}
