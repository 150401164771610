import { __decorate } from "tslib";
import { Vue, Component, Ref } from 'vue-property-decorator';
import TableBuilder from '@/builders/table.builder';
import storagesTableMap from '@/data/storages-table-map';
import CloudStoragesProvider from '@/providers/CloudStoragesProvider';
import CloudStoragesManageModal from '@/components/CloudStoragesManageModal.vue';
let CloudStoragesSettings = class CloudStoragesSettings extends Vue {
    mounted() { }
    async addStorage() {
        const storageResult = await this.$modal.show(CloudStoragesManageModal);
        if (storageResult) {
            await this.table.getProvider().fetch();
        }
    }
};
__decorate([
    Ref()
], CloudStoragesSettings.prototype, "table", void 0);
CloudStoragesSettings = __decorate([
    Component({
        components: {
            StoragesTable: new TableBuilder()
                .withFieldsMap(storagesTableMap)
                .withFields(['name', 'type'])
                .withProvider(CloudStoragesProvider)
                .withActions([
                { icon: 'edit', title: 'Edit storage', handler: 'onStorageEdit' },
                { icon: 'trash', title: 'Delete storage', handler: 'onStorageDelete' },
            ])
                .build(),
        },
    })
], CloudStoragesSettings);
export default CloudStoragesSettings;
