import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import CloudStoragesSettings from '@/components/CloudStoragesSettings.vue';
let GlobalSettingsPage = class GlobalSettingsPage extends Vue {
};
GlobalSettingsPage = __decorate([
    Component({
        components: { CloudStoragesSettings },
    })
], GlobalSettingsPage);
export default GlobalSettingsPage;
