import AbstractControl from '@/classes/abstract-control';
export default class FormControl extends AbstractControl {
    getValue() {
        return this.value;
    }
    patchValue(value) {
        this.setValue(value);
    }
    setValue(value) {
        this.value = value;
        this.validate();
        this.emitOnChange();
    }
}
