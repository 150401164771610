import AbstractControl from '@/classes/abstract-control';
export default class FormGroup extends AbstractControl {
    constructor(controls = {}, initialState, validators) {
        super(initialState, validators);
        this.groupControls = {};
        this.groupControls = controls;
    }
    get controls() {
        return this.groupControls;
    }
    get isValid() {
        return super.isValid && Object.values(this.groupControls).every(control => control.isValid);
    }
    addControl(name, control) {
        this.groupControls[name] = control;
    }
    addControls(controls) {
        this.groupControls = Object.assign(this.controls, controls);
    }
    removeControl(name) {
        if (name in this.controls) {
            this.controls[name].destroy();
            delete this.controls[name];
        }
    }
    getControl(nameOrPath) {
        const path = nameOrPath.split('.');
        return this.findChildControl(path, nameOrPath);
    }
    touch() {
        this.forEachChild(child => child.touch());
    }
    disable() {
        this.forEachChild(child => child.disable());
    }
    enable() {
        this.forEachChild(child => child.enable());
    }
    getValue() {
        return Object.keys(this.controls).reduce((value, controlName) => ({
            ...value,
            [controlName]: this.controls[controlName].getValue(),
        }), {});
    }
    setValue(value) {
        this.assertValue(value);
        this.forEachChild((child, name) => {
            if (value[name]) {
                child.setValue(value[name]);
            }
        });
        this.validate();
        this.emitOnChange();
        this.patchValue(value);
    }
    patchValue(value) {
        this.forEachChild((child, name) => {
            if (value[name]) {
                child.patchValue(value[name]);
            }
        });
        this.validate();
        this.emitOnChange();
    }
    destroy() {
        super.destroy();
        this.forEachChild(child => child.destroy());
    }
    findChildControl(path, fullPath) {
        const remainingPath = path.slice(1);
        const currentPath = path[0];
        if (this.controls[currentPath] && !remainingPath.length) {
            return this.controls[currentPath];
        }
        if (this.controls[currentPath] && remainingPath.length && this.controls[currentPath] instanceof FormGroup) {
            return this.controls[currentPath].findChildControl(remainingPath, fullPath);
        }
        throw new Error(`Control "${fullPath}" not found`);
    }
    assertValue(value) {
        Object.keys(this.controls).forEach(controlName => {
            if (value[controlName] === undefined) {
                throw new Error(`Value cannot be asserted due missing value for '${controlName}'`);
            }
        });
    }
    forEachChild(cb) {
        Object.keys(this.controls).forEach(controlName => {
            cb(this.controls[controlName], controlName);
        });
    }
}
