const storagesTableMap = {
    name: {
        label: 'Name',
    },
    type: {
        label: 'Storage Type',
    },
    actions: {
        label: '',
        thStyle: {
            width: '75px',
        },
    },
};
export default storagesTableMap;
