import FTable from '@/components/FTable.vue';
import TableRenderer from '@/components/TableRenderer.vue';
import FIcon from '@/components/FIcon.vue';
export default class TableBuilder {
    constructor() {
        this.fieldsMap = {};
        this.fields = [];
    }
    get mappedFields() {
        return this.fields.map(field => ({ ...this.fieldsMap[field], key: field }));
    }
    get tableProps() {
        return {
            fields: this.mappedFields,
        };
    }
    withProvider(provider) {
        this.provider = provider;
        return this;
    }
    withFields(fields) {
        this.fields = fields;
        return this;
    }
    withFieldsMap(fieldsMap) {
        this.fieldsMap = fieldsMap;
        return this;
    }
    withActions(actions) {
        this.actions = actions;
        this.fields.push('actions');
        return this;
    }
    build() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const builder = this;
        const Provider = this.provider;
        const { tableProps } = this;
        return {
            methods: {
                getProvider() {
                    return this.$refs.provider;
                },
            },
            render(h) {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const proxyInstance = this;
                return h(Provider, {
                    ref: 'provider',
                    scopedSlots: {
                        default: providerProps => h(TableRenderer, {
                            props: { ...providerProps },
                            scopedSlots: {
                                default: () => h(FTable, {
                                    props: { ...tableProps, items: providerProps.data || [] },
                                    scopedSlots: {
                                        ...builder.renderActionsTableSlot(h, providerProps.emitter),
                                        ...(proxyInstance?.$scopedSlots ? proxyInstance.$scopedSlots : {}),
                                    },
                                }),
                            },
                        }),
                    },
                });
            },
        };
    }
    renderActionsTableSlot(h, emitter) {
        if (!this.actions) {
            return {};
        }
        return {
            'cell(actions)': props => {
                return h('div', {
                    staticClass: 'd-flex align-items-center',
                }, [
                    this.actions.map(action => h('button', {
                        on: {
                            click() {
                                emitter(action.handler, props?.item);
                            },
                        },
                        staticClass: 'table-row-button mr-2',
                        directives: [
                            {
                                name: 'b-tooltip',
                                value: { customClass: 'f-tooltip', title: action.title },
                                modifiers: {
                                    hover: true,
                                    left: true,
                                },
                            },
                        ],
                    }, [h(FIcon, { props: { name: action.icon } })])),
                ]);
            },
        };
    }
}
