export default class FormControlsBuilder {
    constructor(formGroup) {
        this.elements = [];
        this.formGroup = formGroup;
    }
    addInput(name, label, props = {}) {
        this.elements.push({
            name,
            label,
            props,
            component: 'b-form-input',
            control: this.formGroup.getControl(name),
        });
        return this;
    }
    addSelect(name, label, props = {}) {
        this.elements.push({
            name,
            label,
            props,
            component: 'b-form-select',
            control: this.formGroup.getControl(name),
        });
        return this;
    }
    build() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const builder = this;
        return {
            data() {
                return {
                    controls: builder.formGroup,
                };
            },
            render(h) {
                return h('div', {}, builder.elements.map(element => FormControlsBuilder.renderElement(h, element)));
            },
        };
    }
    static renderElement(h, element) {
        return h('b-form-group', {
            props: {
                label: element.label,
                labelSize: 'sm',
            },
        }, [
            h(element.component, {
                props: {
                    size: 'sm',
                    ...(element.props || {}),
                    value: element.control.getValue(),
                    disabled: element.control.isDisabled,
                    state: element.control.state,
                },
                on: {
                    change(value) {
                        element.control.touch();
                        element.control.setValue(value);
                    },
                },
            }),
        ]);
    }
}
