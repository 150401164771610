import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let TableRenderer = class TableRenderer extends Vue {
};
__decorate([
    Prop({
        default: false,
    })
], TableRenderer.prototype, "loading", void 0);
TableRenderer = __decorate([
    Component({})
], TableRenderer);
export default TableRenderer;
