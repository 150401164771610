import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
/**
 * BaseProvider cannot be abstract since it should have a Component decorator!
 */
let BaseProvider = class BaseProvider extends Vue {
    constructor() {
        super(...arguments);
        this.eventBus = new Vue();
    }
    get props() {
        return {};
    }
    beforeDestroy() {
        this.eventBus.$destroy();
    }
    render() {
        if (!this.$scopedSlots.default) {
            throw new Error('Provider should have a default slot!');
        }
        return this.$scopedSlots.default({
            ...this.props,
            emitter: this.$emit.bind(this.eventBus),
        });
    }
};
BaseProvider = __decorate([
    Component({})
], BaseProvider);
export default BaseProvider;
