import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { StorageType } from '@flosum-backup/contracts';
import StoragesApi from '@/services/api/storages.api';
import BaseProvider from '@/providers/BaseProvider';
import CloudStoragesManageModal from '@/components/CloudStoragesManageModal.vue';
let CloudStoragesProvider = class CloudStoragesProvider extends BaseProvider {
    constructor() {
        super(...arguments);
        this.data = null;
        this.loading = false;
    }
    get props() {
        return {
            data: this.data,
            loading: this.loading,
        };
    }
    async created() {
        this.bindEvents();
        await this.fetch();
    }
    async onStorageDelete(item) {
        const isDeleteConfirmed = await this.$confirm('All backups will be deleted from this storage', `Delete "${item.name}" storage`);
        if (isDeleteConfirmed) {
            try {
                this.loading = true;
                await StoragesApi.delete(item.id);
                this.$toast.success('Cloud storage was successfully deleted');
                await this.fetch();
            }
            catch (error) {
                this.$toast.error(error.message);
            }
            finally {
                this.loading = false;
            }
        }
    }
    async onStorageEdit(item) {
        const editResult = await this.$modal.show(CloudStoragesManageModal, {
            id: item.id,
        });
        if (editResult) {
            await this.fetch();
        }
    }
    async fetch() {
        try {
            this.loading = true;
            this.data = (await StoragesApi.findAll()).filter(storage => storage.type !== StorageType.Local);
        }
        catch (error) {
            this.$toast.error(error);
        }
        finally {
            this.loading = false;
        }
    }
    bindEvents() {
        this.eventBus.$on('onStorageDelete', this.onStorageDelete.bind(this));
        this.eventBus.$on('onStorageEdit', this.onStorageEdit.bind(this));
    }
};
CloudStoragesProvider = __decorate([
    Component({})
], CloudStoragesProvider);
export default CloudStoragesProvider;
