export default class AbstractControl {
    constructor(initialState, validators) {
        this.controlValidators = [];
        this.onChangeListeners = [];
        this.touched = false;
        this.valid = true;
        this.disabled = false;
        this.value = null;
        if (initialState) {
            this.value = initialState;
        }
        if (validators) {
            this.controlValidators = validators;
        }
    }
    get isDisabled() {
        return this.disabled;
    }
    get isValid() {
        return this.valid;
    }
    get state() {
        return this.valid ? null : false;
    }
    disable() {
        this.disabled = true;
    }
    enable() {
        this.disabled = false;
    }
    touch() {
        this.touched = true;
        this.validate();
    }
    destroy() {
        this.onChangeListeners = [];
    }
    onChange(listener) {
        this.onChangeListeners.push(listener);
    }
    validate() {
        if (this.controlValidators.length && this.touched) {
            this.valid = this.controlValidators.some(validator => validator(this.getValue()));
        }
    }
    emitOnChange() {
        this.onChangeListeners.forEach(listener => listener(this.getValue()));
    }
}
